import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Message from '../../enb-styled/Message.js';
import TableMaintainEmployeeBankDetails from '../../markdowns/sections/docs/payroll/parameters-maintain-employee-bank-details.md';
import TableRetrieveEmployeeBankAccounts from '../../markdowns/sections/docs/payroll/response-retrieve-employee-bank-details.md';
import TableMaintainEmployeeSuperAnnuation from '../../markdowns/sections/docs/payroll/parameters-maintain-employee-superannuation.md';
import TableRetrieveEmployeeSuperAnnuation from '../../markdowns/sections/docs/payroll/response-retrieve-employee-superannuation.md';
import TableMaintainEmployeeTaxDetails from '../../markdowns/sections/docs/payroll/parameters-maintain-employee-tax-details.md';
import TableRetrieveEmployeeTaxDetails from '../../markdowns/sections/docs/payroll/response-retrieve-employee-tax-details.md';
import TableRetrieveEmployeeLeaveBalances from '../../markdowns/sections/docs/payroll/response-retrieve-employee-leave-balances.md';
import TableMaintainEmployeeLeaveBalances from '../../markdowns/sections/docs/payroll/parameters-maintain-employee-leave-balances.md';
import TableRetrieveEmployeePayrollDetails from '../../markdowns/sections/docs/payroll/response-retrieve-employee-payroll-details.md';
import TableMaintainEmployeePayrollDetails from '../../markdowns/sections/docs/payroll/parameters-maintain-employee-payroll-details.md';
export const _frontmatter = {
  "title": "Payroll"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Payroll`}</h1>
    <h2>{`Definitions`}</h2>
    <p>{`enableHR Employee records are enhanced with Payroll related fields.`}</p>
    <p><strong parentName="p">{`For Australian accounts:`}</strong></p>
    <ul>
  <li>
    Bank Accounts:
    <ul>
      <li>Up to 4 bank accounts are supported. The order in which accounts are to be considered (by the payroll system) is mandatory.</li>
      <li>A Bank Account is made of a BSB and Account number together.</li>
      <li>Each bank account has a “Dollar amount” that represents the $ figure that the payroll system will use for payment into this bank account. The “Remainder” option is used for “everything left”.</li>
    </ul>
  </li>
  <li>
    Tax:
    <ul>
      <li>All the essential details needed for the Australian taxation purpose are supported, including Tax File Number, Residency Status, Employment basis, Tax free threshold, HECS and Trade Support Loan.</li>
      <li>TFN is masked except for the last 3 digits (###-###-123), if un-masked TFN is needed for Payroll integration purposes, please contact Client Success at <a href="mailto:support@enablehr.com">support@enablehr.com</a>.</li>
    </ul>
  </li>
  <li>Superannuation: Supports industry or commercial super funds and as well Self-Managed Super Funds. Up to 4 Superannuation funds can be maintained for an employee including Fund name, Fund address, Fund ABN and USI. </li>
  <li>Leave Balances: Leave balances information can be maintained for Australian employees includes Annual, Personal and Long Service leaves.</li>
    </ul>
    <p><strong parentName="p">{`For New Zealand accounts:`}</strong></p>
    <ul>
  <li>
    Bank Accounts:
    <ul>
      <li>Up to 4 bank accounts are supported. The order in which accounts are to be considered (by the payroll system) is mandatory</li>
      <li>16-digit account number which includes Bank number, Branch Number, Account number and suffixes.</li>
      <li>Each bank account has a “Dollar amount” that represents the $ figure that the payroll system will use for payment into this bank account. The “Remainder” option is used for “everything left”.</li>
    </ul>
  </li>
  <li>Tax: Mandatory tax information including Tax Code, Student loan percentage and IRD number are maintained in enableHR.</li>
  <li>Kiwisaver: Retirement saving information for New Zealand employees includes Employee & Employer contributions and as well as pre-defined Employer superannuation contribution tax (ESCT) are maintained.</li>
  <li>Leave Balances: Majorly used leave balances information can be maintained for New Zealand employees includes Annual, Sick, Personal, Long Service, Bereavement and Domestic Violence leaves.</li>
    </ul>
    <h2>{`Add / Update / Delete Bank Accounts`}</h2>
    <p>{`Use this action to Add, Update or Delete employee bank details.`}</p>
    <Message mdxType="Message">
      <p>The same action is used to add, update and delete Bank Accounts in Australia and New Zealand. The API call knows if the Account is in Australia or New Zealand, so you don’t have to specify it.</p>
      <p>enableHR supports up to 4 bank accounts. The order in which accounts are to be considered (by the payroll system) is mandatory.</p>
      <ol>
  <li>If the Employee doesn’t have any bank account, the ones passed through the API are added.</li>
  <li>If the Employee already has some bank account details, they will be updated with the new ones.</li>
  <li>If the API doesn’t send any bank account details, then the existing details for the Employee will be deleted.</li>
      </ol>
      <p>Each bank account has a “Dollar amount” that represents the $ figure that the payroll system will use for payment into this bank account. The “Remainder” option is used for “everything left”.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-bank-accounts.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/MaintainEmployeeBankAccounts/"
              }}>{`PUT: Maintain Employee Bank Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br />{`  `}<a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableMaintainEmployeeBankDetails mdxType="TableMaintainEmployeeBankDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3>{`Australian Accounts`}</h3>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-bank-accounts-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h3>{`New Zealand Accounts`}</h3>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-bank-accounts-ui-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Retrieve Bank Accounts`}</h2>
    <p>{`Use this action to retrieve the Bank Account details of an Employee.`}</p>
    <Message mdxType="Message">
enableHR supports up to 4 bank accounts. The order in which accounts are to be considered (by the payroll system) is mandatory.
      <p>{`Each bank account has a “Dollar amount” that represents the $ figure that the payroll system will use for payment into this bank account. The “Remainder” option is used for “everything left”.`}</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-bank-accounts.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeeBankAccounts/"
              }}>{`GET: Retrieve Bank Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployeeBankAccounts mdxType="TableRetrieveEmployeeBankAccounts" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3>{`Australian Accounts`}</h3>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-bank-accounts-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h3>{`New Zealand Accounts`}</h3>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-bank-accounts-ui-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Add / Update / Delete Superannuation / Kiwisaver`}</h2>
    <p>{`Use this action to Add, Update or Delete employee Superannuation (Australia) or Kiwisaver (New Zealand) details.`}</p>
    <Message mdxType="Message">
      <p>The same action is used to add, update and delete Super Funds in Australia and New Zealand. The API call knows if the Account is in Australia or New Zealand, so you don’t have to specify it.</p>
      <ol>
  <li>If the Superannuation / Kiwisaver details are not already present, they will be added</li>
  <li>If the Superannuation / Kiwisaver details already exist, they are updated with the new details.</li>
  <li>When the details are empty, the input details are removed, Superannuation / Kiwisaver details for the employee will be deleted.</li>
      </ol>
      <p>enableHR supports up to 4 super funds in Australia that can include either Regulated or Self-Managed Super Fund types.
Multiple Superannuation records can be maintained for an employee including Fund name, Fund address, Fund ABN and USI.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-superannuation.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/MaintainEmployeeRetirementSaving/"
              }}>{`PUT: Maintain Employee Retirement Saving Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account `}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableMaintainEmployeeSuperAnnuation mdxType="TableMaintainEmployeeSuperAnnuation" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>{`Australian Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-superannuation-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h4>{`New Zealand Accounts`}</h4>
    <p>{`When status is active:`}</p>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-superannuation-active-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <p>{`When Status is Opt-out:`}</p>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-superannuation-opt-out-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <p>{`When Status is Suspended:`}</p>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-superannuation-suspended-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Retrieve Superannuation / Kiwisaver`}</h2>
    <p>{`Use this action to retrieve the details of  Superannuation (Australia) or Kiwisaver (New Zealand) of an Employee.`}</p>
    <Message mdxType="Message">
      <p>enableHR supports up to 4 super funds in Australia that can include either Regulated or Self-Managed Super Fund types.
Multiple Superannuation records can be maintained for an employee including Fund name, Fund address, Fund ABN and USI.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-superannuation.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeeRetirementSavings/"
              }}>{`GET: Retrieve Superannuation / Kiwisaver details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployeeSuperAnnuation mdxType="TableRetrieveEmployeeSuperAnnuation" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>{`Australian Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-superannuation-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h4>{`New Zealand Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-superannuation-ui-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Add / Update Tax Details`}</h2>
    <p>{`Use this action to Add or Update the Tax details of an Employee.`}</p>
    <Message mdxType="Message">
      <p>The same action is used to add or update the Tax details in Australia and New Zealand. The API call knows if the Account is in Australia or New Zealand, so you don’t have to specify it.</p>
      <p>If the tax details already exist, it will be updated with the new input details otherwise it will be created.</p>
      <p>All the essential details needed for the Australian taxation purpose are supported, including Tax File Number, Residency Status, Employment basis, Tax free threshold, HECS and Trade Support Loan.<br />
TFN is always masked except for the last 3 digits (###-###-123), if un-masked TFN is needed for Payroll integration purposes, please contact <a href="mailto:support@enablehr.com">Client Success</a>.</p>
      <p>In New Zealand, all mandatory tax information including Tax Code, Student loan percentage and IRD number are maintained in enableHR.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-tax-details.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/MaintainEmployeeTaxDetails/"
              }}>{`PUT: Add/Update Tax Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableMaintainEmployeeTaxDetails mdxType="TableMaintainEmployeeTaxDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>{`Australian Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-tax-details-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h4>{`New Zealand Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/add-tax-details-ui-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Retrieve Tax Details`}</h2>
    <p>{`Use this action to Add or Update the Tax details of an Employee.`}</p>
    <Message mdxType="Message">
      <p>All the essential details needed for the Australian taxation purpose are supported, including Tax File Number, Residency Status, Employment basis, Tax free threshold, HECS and Trade Support Loan.<br />
TFN is always masked except for the last 3 digits (###-###-123), if un-masked TFN is needed for Payroll integration purposes, please contact <a href="mailto:support@enablehr.com">Client Success</a>.</p>
      <p>In New Zealand, all mandatory tax information including Tax Code, Student loan percentage and IRD number are maintained in enableHR.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-tax-details.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeeTaxDetails/"
              }}>{`GET: Retrieve tax Details of an Employee`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployeeTaxDetails mdxType="TableRetrieveEmployeeTaxDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>{`Australian Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-tax-details-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h4>{`New Zealand Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-tax-details-ui-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Leave Balances`}</h2>
    <p>{`Although enableHR doesn’t provide Leave Management feature, it stores Leave Balances so that managers and employees can visualise them.
In Australia, the following leave types are currently supported:`}</p>
    <ul>
      <li parentName="ul">{`Annual Leave`}</li>
      <li parentName="ul">{`Personal Leave`}</li>
      <li parentName="ul">{`Long Service Leave`}</li>
    </ul>
    <p>{`In New Zealand, the following leave types are currently supported:`}</p>
    <ul>
      <li parentName="ul">{`Annual Leave`}</li>
      <li parentName="ul">{`Sick Leave`}</li>
      <li parentName="ul">{`Long Service Leave`}</li>
      <li parentName="ul">{`Domestic Violence leave`}</li>
      <li parentName="ul">{`Bereavement leave`}</li>
      <li parentName="ul">{`Parental leave`}</li>
      <li parentName="ul">{`Alternative Holidays`}</li>
    </ul>
    <h2>{`Retrieve Leave Balances`}</h2>
    <p>{`Use this action to retrieve the Leave Balances of an Employee.`}</p>
    <Message mdxType="Message">
      <p>In Australia, the following leave types are currently supported: Annual Leave, Personal Leave, Long Service Leave.</p>
      <p>In New Zealand, the following leave types are currently supported: Annual Leave, Sick Leave, Long Service Leave, Domestic Violence leave, Bereavement leave, Parental leave, Alternative Holidays.</p>
      <p>Units can be in days or hours as maintained by client.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-leave-balances.png" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeeLeaveBalances/"
              }}>{`GET: Retrieve Employee Leave balances`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployeeLeaveBalances mdxType="TableRetrieveEmployeeLeaveBalances" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>{`Australian Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-leave-balances-ui-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h4>{`New Zealand Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrieve-leave-balances-ui-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Update Leave Balances`}</h2>
    <p>{`Use this action to add or update Leave Balances of an Employee.`}</p>
    <Message mdxType="Message">
      <p>The same action is used to add or update the Leave Balances in Australia and New Zealand. The API call knows if the Account is in Australia or New Zealand, so you don’t have to specify it.</p>
      <p>In Australia, the following leave types are currently supported: Annual Leave, Personal Leave, Long Service Leave.</p>
      <p>In New Zealand, the following leave types are currently supported: Annual Leave, Sick Leave, Long Service Leave, Domestic Violence leave, Bereavement leave, Parental leave, Alternative Holidays.</p>
      <p>Units can be in days or hours as maintained by client.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/update-leave-balance.jpg" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/MaintainEmployeeLeaveBalances/"
              }}>{`PUT: Maintain Leave Balances`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Accepted Fields`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableMaintainEmployeeLeaveBalances mdxType="TableMaintainEmployeeLeaveBalances" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>{`Australian Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/update-leave-balances-au.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h4>{`New Zealand Accounts`}</h4>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/update-leave-balances-nz.png" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Retrieve Payroll Details`}</h2>
    <p>{`Use this action to retrieve payroll details of an Employee.`}</p>
    <Message mdxType="Message">
      <p>Payroll details are very much common elements to be used for Payroll processing both in Australia and New Zealand.</p>
      <p>Hours Per Week, Pay Rate, Rate Unit, Pay Schedule, Payroll Company and Pay Group are the fields available through this end point.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/retrievepayroll.jpg" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeePayrollDetails/"
              }}>{`GET: Retrieve Employee Payroll Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployeePayrollDetails mdxType="TableRetrieveEmployeePayrollDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/payrolldetails.jpg" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <h2>{`Add/Update Payroll Details`}</h2>
    <p>{`Use this action to maintain payroll details of an Employee.`}</p>
    <Message mdxType="Message">
      <p>Payroll details are very much common elements to be used for Payroll processing both in Australia and New Zealand.</p>
      <p>Hours Per Week, Pay Rate, Rate Unit, Pay Schedule, Payroll Company and Pay Group are the fields available for maintenance through this end point.</p>
    </Message>
    <div className="m-5 text-center">
  <img src="/images/docs/payroll/maintainpayroll.jpg" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/MaintainEmployeePayrollDetails/"
              }}>{`PUT: Maintain Employee Payroll Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableMaintainEmployeePayrollDetails mdxType="TableMaintainEmployeePayrollDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      